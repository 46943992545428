import { Component } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BrowserInfo, detect } from 'detect-browser';
import { faExclamationTriangle, faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { IsiStateService } from '@api/isi-state.service';
import { StateService } from '@api/state.service';
import { AuthService } from '@api/auth.service';
import { User } from '@models/user';
import { TwilioService } from '@api/twilio.service';
import { SessionActivePage } from '@pages/session-active/session-active.component';
import { TimeZoneService } from '@api/time-zone.service';
import { ZoomService } from '@api/zoom.service';

export let IsBrowserRefreshed = false;

@Component({
  selector: 'oamw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  browserInfo: BrowserInfo;
  isValidBrowser = true;
  isMacDevice = false;
  faExclamationTriangle = faExclamationTriangle;
  faBan = faBan;
  faCheckCircle = faCheckCircle;
  subscription: Subscription;

  title = 'Voce';
  isLoginPage = false;
  isLandingPage = false;
  isTermsPage = false;
  isMenuOpen = false;
  isLoggedIn = true;
  isTempHideHeader = true;
  isIsiVisible = false;
  isIsiConfirmVisible = false;
  isTermsChecked = false;
  user: User;
  route: Router;
  endModalVisible = false;
  isConnected = false;
  isFullWidth = false;


  constructor(private isiState: IsiStateService,
              private router: Router,
              private state: StateService,
              private twilioService: TwilioService,
              private zoomService: ZoomService,
              private timeZoneService: TimeZoneService,
              private auth: AuthService) {
                this.route = router;
                this.subscription = router.events.subscribe((event) => {
                  if (event instanceof NavigationStart) {
                    IsBrowserRefreshed = !router.navigated;
                  }
                });
              }

  ngOnInit() {
    if(navigator.platform.match('Mac') !== null) {
      document.body.classList.add('OSX');
      // document.getElementById('menu').setAttribute('class', 'mac-menu');
    }
    this.setBrowserValidity();

    this.isLandingPage = this.router.isActive('/', true);
    this.isLoginPage = this.router.isActive('/login', true);
    this.isTermsPage = this.router.isActive('/confirm-isi', true);

    this.router
        .events
        .pipe(filter(ev => ev instanceof NavigationEnd))
        .subscribe((ev: NavigationEnd) => {
          this.isLoginPage = ev.url === '/login';
          this.isLandingPage = ev.url === '/';
          this.isTermsPage = ev.url === '/confirm-isi';
          this.isFullWidth = this.isLandingPage || this.isLoginPage || this.isTermsPage || ev.url === '/start-session' || ev.url === '/session-active' || ev.url === '/rating';
        });

    this.auth.isLoggedIn.subscribe( status => this.isLoggedIn = status );

    this.isiState.isiVisible.subscribe(state => {
      setTimeout(() => { this.isIsiVisible = state; }, 0);
    });

    this.isiState.isiConfirmVisible.subscribe(state => {
      setTimeout(() => { this.isIsiConfirmVisible = state; }, 0);
    });

    this.auth.user.subscribe(u => (this.user = u));

    this.twilioService.isConnected.subscribe(state => {
      setTimeout(() => { this.isConnected = state; }, 0);
    });

    this.getTimeZones();
  }

  confirmIsiRead() {
    this.isTermsChecked = true;
    this.isiState.isiConfirmClick();
  }

  logout() {
    if (this.route.url === '/session-active' && this.state.isSessionActive) {
      this.endModalVisible = true;
    } else {
    this.zoomService.disposeLocalMedia();
    this.zoomService.leaveSession(this.user.type === "CAPTAIN");
    this.auth.logout();
    this.router.navigate(['/']);
    this.isMenuOpen = false;
    }
  }

  disconnectRoomAndLogout() {
    this.zoomService.disposeLocalMedia();
    this.zoomService.leaveSession(this.user.type === "CAPTAIN");
    this.endModalVisible = false;
    this.auth.logout();
    this.router.navigate(['/']);
  }

  getTimeZones() {
    this.timeZoneService
    .getTimeZones()
    .subscribe(timezones => {
      this.state.timeZones = timezones;
      console.log(this.state.timeZones);
    });
  }


 ///

  private setBrowserValidity() {
    this.browserInfo = detect(navigator.userAgent) as BrowserInfo;
    this.isMacDevice = this.browserInfo.os === 'Mac OS' ||
                       this.browserInfo.os === 'iOS';
    console.log(this.browserInfo);

    switch (this.browserInfo.name) {
      case 'chrome':
        this.isValidBrowser = !this.isMacDevice && parseFloat(this.browserInfo.version) > 65;
        break;

      case 'edge-chromium':
        this.isValidBrowser = !this.isMacDevice && parseFloat(this.browserInfo.version) > 78;
        break;

      case 'firefox':
        this.isValidBrowser = !this.isMacDevice && parseFloat(this.browserInfo.version) > 43;
        break;

      case 'opera':
        this.isValidBrowser = !this.isMacDevice && parseFloat(this.browserInfo.version) > 42;
        break;

      case 'ios':
      case 'safari':
        this.isValidBrowser = parseFloat(this.browserInfo.version) >= 11;
        break;

      default:
        console.log('None of above matches!');
        this.isValidBrowser = false;
        break;
    }

  }
}
