<div class="column">
  <section id="setAvailability" class="mb-5 bg-grey">
    <div class="col d-flex align-items-center" *ngIf="!timeSectionVisible">
      <span (click)="goBack()" class="pointer left-arrow">
        <img class="left-arrow" src="assets/images/angle-left-orange.svg" class="back-arrow">
      </span>
    </div>
    <div class="col d-flex align-items-center" *ngIf="timeSectionVisible">
      <span (click)="goDateSection()" class="pointer left-arrow">
        <img class="left-arrow" src="assets/images/angle-left-orange.svg" class="back-arrow">
      </span>
    </div>
    <div>
      <div class="row mb-3">
        <div class="col d-flex align-items-center header">
          <h2>Set Availability</h2>
          <p>Select dates and time you are available to conduct video sessions</p>
        </div>
      </div>
      <!-- <div class="row"><div class="col text-center mt-5"><div id="calendar"><bs-datepicker-inline [bsConfig]="datepickerConfig" (bsValueChange)="onValueChange($event)"></bs-datepicker-inline></div></div></div><div class="row"><div class="col text-center mt-3"><button type="button" class="btn btn-primary btn-desktop bg-purple my-4" (click)="goTimeSection()">Next</button></div></div> -->
      <ng-template #customCellTemplate let-day="day" let-openDay="openDay" let-locale="locale" let-tooltipPlacement="tooltipPlacement"
                   let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay" let-eventClicked="eventClicked"
                   let-trackByEventId="trackByEventId" let-validateDrag="validateDrag">
        <div class="cal-cell-top">
          <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal}}</span>
          <span class="cal-day-number">{{ day.date | calendarDate: 'monthViewDayNumber':locale}}</span>
        </div>
        <div class="cal-events" *ngIf="day.events.length > 0">
          <div class="cal-event" *ngFor="let event of day.events | slice:0:showAll ? undefined :3; trackBy: trackByEventId"
               [ngStyle]="{ backgroundColor: event.color?.primary }" [ngClass]="event?.cssClass">
            {{event.title}}
          </div>
        </div>
      </ng-template>
      <div class="col text-a-c mb-3 select-month">
        <div class="btn-group">
          <span class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" class="pointer">
            <img src="assets/images/arrow-left.svg" class="back-arrow">
          </span>
          <h1>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h1>
          <span class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" class="pointer">
            <img src="assets/images/arrow-right.svg" class="back-arrow">
          </span>
        </div>
      </div>
      <div [ngSwitch]="view">
        <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                 (dayClicked)="dayClicked($event.day)" [cellTemplate]="customCellTemplate"
                                 (eventClicked)="handleEvent('Clicked', $event.event)"></mwl-calendar-month-view>
      </div>
    </div>
    <!--  Select Time Popup  -->
    <div id="confirm-popup" *ngIf="selectModalVisible">
      <div class="confirm-body mt-5">
        <button type="button" class="menu-close close confirm-btn-close" aria-label="Close" (click)="closeSelectModal()">
          <span aria-hidden="true">
            <img src="assets/images/close_black.svg" class="menu-close-img">
          </span>
        </button>
        <div>
          <div class="row">
            <div class="col d-flex align-items-center">
              <h3 class="">Set the times that you are available on</h3>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex align-items-center">
              <h1 class="font-color-teracotta">{{selectedMonth}} {{selectedDate}}, {{selectedYear}}</h1>
            </div>
          </div>

          <div class="row" *ngFor="let a of availablility; index as i">
            <div class="col">
              <div class="timeslot">
                <div class="row no-gutters">
                  <div class="col-5 p-2">
                    <div class="ke-select-wrapper" (click)="toggleDropdown($event)">
                      <div class="ke-select">
                        <div class="ke-select__trigger">
                          <span>{{ a.stime || 'Start' }}</span>
                          <div class="arrow"></div>
                        </div>
                        <div class="custom-options">
                          <span class="custom-option" *ngFor="let s of timings" [class.selected]="a.stime === s" (click)="selectStart(s, i)">{{ s }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 timeslot-text text-center font-color-teracotta d-flex justify-content-center align-items-center px-0"> to:</div>
                  <div class="col-5 p-2">
                    <div class="ke-select-wrapper" (click)="toggleDropdown($event)">
                      <div class="ke-select">
                        <div class="ke-select__trigger">
                          <span>{{ a.etime || 'Finish' }}</span>
                          <div class="arrow"></div>
                        </div>
                        <div class="custom-options">
                          <span class="custom-option" *ngFor="let s of timings | slice: timings.indexOf(a.stime) + 1 : timings.length" [class.selected]="a.etime === s" (click)="selectEnd(s, i)">{{ s }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-center d-flex justify-content-center align-items-center" (click)="deleteTimings(i)">
                    <img src="assets/images/trash.svg" class="delete-btn">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col text-center add-btn">
              <button type="button" class="btn hidden font-color-teracotta" *ngIf="availablility[availablility.length - 1].stime === null">
                Add Time
              </button>
              <button type="button" class="btn font-color-teracotta" *ngIf="availablility[availablility.length - 1].stime !== null"
                      (click)="addTimings()">
                Add Time
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <button type="button" [disabled]="disabledButton" (click)="addAvailabilities()" class="btn btn-primary btn-desktop bg-purple my-4">
                Complete
              </button>
            </div>
          </div>
          <div class="validation-error-message" *ngIf="errorMessage">
            <span>{{errorMessage}}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
