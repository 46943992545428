<div class="column">
  <section id="upcomingSessionsUser" class="bg-grey"
    [ngClass]="sessions != undefined && sessions.length > 0 ? 'upcoming-list' : 'upcoming-list'">
    <div class="container">
      <div class="row" *ngIf="!isDisplayed">
        <div class="col text-center mb-4">
          <h2>Loading...</h2>
        </div>
      </div>
      <div *ngIf="isDisplayed">
        <div *ngIf="sessions != undefined && sessions.length > 0">
          <div class="row pb-3">
            <div class="col">
              <button type="button" class="btn mt-2 btn-top-add-session" *ngIf="sessionsLeft > 0"
                [routerLink]="['/select-topic']"><span class="ico">+</span><u class="no-text-decoration">Schedule New
                  Session</u></button>
              <button type="button" class="btn mt-2 btn-top-add-session" disabled *ngIf="sessionsLeft < 1"
                [routerLink]="['/select-topic']"><span class="ico">+</span><u class="no-text-decoration">Schedule New
                  Session</u></button>
              <div class="validation-error-message" *ngIf="sessionsLeft < 1">
                <span>You have booked maximum allowed session.</span>
              </div>
            </div>
          </div>
          <div class="row pb-3" *ngFor="let sess of sessions">
            <div class="col">
              <div class="card-session">
                <div class="row mobile-sessions">
                  <div class="col">
                    <h2>Date: {{ sess.date }} {{ sess.time }} {{ sess.zoneAbbr }}</h2>
                    <h2>Topic: {{ sess.topic }}</h2>
                    <h2>Ambassador: {{ sess.captainFirstName }} {{ sess.captainLastName }}</h2>
                  </div>
                  <div class="col-3 btn-container">
                    <div class="button mb-3">
                      <button type="button" class="btn-cancel" (click)="showDeleteModal(sess.id)">
                        <img class="button-icon" src="assets/images/trash.svg" />Delete
                      </button>
                    </div>
                    <div class="button-text">
                      <button type="button" class="btn-cancel" (click)="joinSession(sess.id, sess.ts)">
                        <img class="button-icon" src="assets/images/person-video.svg" />Join Session
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isDisplayNot && (sessions==undefined || sessions.length < 1)">
          <div class="col no-session-text">
            No Upcoming Sessions
          </div>
          <div class="row pt-2 upcoming-btn">
            <div class="col text-center">
              <a type="button" class="btn btn-no-add-session mb-5" *ngIf="sessionsLeft > 0"
                [routerLink]="['/select-topic']">Schedule New Session</a>
              <a type="button" class="btn btn-no-add-session mb-5 disabled" *ngIf="sessionsLeft < 1">Schedule New
                Session</a>
              <div class="validation-error-message" *ngIf="sessionsLeft < 1">
                <span>You have booked maximum allowed session.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Terms Modal -->
    <div id="confirm-popup" *ngIf="deleteModalVisible">
      <div class="confirm-body mt-5">
        <div>
          <div>
            <div class="col text-center">
              <p>Are you sure you want to cancel your appointment?</p>
            </div>
          </div>
        </div>

        <div class="confirm-tray">
          <div class="row">
            <div class="col">
              <button type="button" class="btn btn-secondary bg-light" (click)="deleteModalVisible = false">No</button>
            </div>
            <div class="col">
              <button type="button" class="btn btn-primary" (click)="deleteSession()">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>