import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'oamw-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqPage implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if(navigator.platform.match('Mac') !== null) {
      document.getElementById('faq').classList.add('mac-faq');
    }

  }

}
