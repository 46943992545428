import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {
  AppointmentData, Appointment, ParticipantArrivalData,
  SessionCompleteData, CaptainArrivalData, OverdueRemindData
} from '@models/appointment';
import { handleError } from '@utils/handleError';


@Injectable({ providedIn: 'root' })
export class AppointmentsService {

  private APPOINTMENT_URL = environment.apiUrl + '/appointments';
  private UPCOMING_APPOINTMENT_URL = this.APPOINTMENT_URL + '/upcoming';
  private TOKEN_URL = environment.apiUrl + '/sessions/generate-token';


  constructor(private http: HttpClient) { }

  getAvailabilityByCaptain(id: string) {
    return this.getAppointmentsByUser(id, 'captainId');
  }

  getAvailabilityByParticipant(id: string) {
    return this.getAppointmentsByUser(id, 'participantId');
  }

  addAppointment(data: AppointmentData) {
    return this.http
               .post(this.APPOINTMENT_URL, data)
               .pipe(
                 map(res => res),
                 catchError(this.handleError())
               );
  }

  deleteAppointment(id: string) {
    return this.http
               .delete<Appointment[]>(this.APPOINTMENT_URL, { params: new HttpParams().append('id', id) })
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }

  captainArrival(data: CaptainArrivalData) {
    return this.http
                .post<{ token: string }>(this.TOKEN_URL, {
                  appointmentId: data.appointmentId,
                  name: data.captainId,
                  topic: data.appointmentId,
                  roleType: 1,
                })
               .pipe(
                 map(({ token }) => token),
                 catchError(handleError())
               );
  }

  participantArrival(data: ParticipantArrivalData) {
    return this.http
                .post<{ token: string }>(this.TOKEN_URL, {
                  appointmentId: data.appointmentId,
                  name: data.participantId,
                  topic: data.appointmentId,
                  roleType: 0
                })
               .pipe(
                 map(({ token }) => token),
                 catchError(handleError())
               );
  }

  completedAppointment(data: SessionCompleteData) {
    return this.http
               .post(this.APPOINTMENT_URL + '/completedAppointment', data)
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }

  overdueReminder(data: OverdueRemindData) {
    return this.http
               .post(this.APPOINTMENT_URL + '/sendOverdueReminder', data)
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }

  updateDuration(appointmentId: string) {
    return this.http
               .put(this.APPOINTMENT_URL + '/updateDuration?appointmentId=' + appointmentId, {})
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }

  ///

  private getAppointmentsByUser(id: string, type: string) {
    return this.http
               .get<Appointment[]>(this.UPCOMING_APPOINTMENT_URL, { params: new HttpParams().append(type, id) });
  }

  private handleError() {
    return (err: any) => {
      console.error('ERROR:', err.error.message);
      const error = new Error();
      error.message = err.error.message;
      return of(error);
    };
  }

}
