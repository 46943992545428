<div class="pt-10p" id="activeSession">

  <details *ngIf="userType === 'CAPTAIN' && !!adrResults && adrResults.drugMentioned && adrResults.adrs?.length > 0">
    <summary>ADR Detected</summary> 
    <ul>
      <li *ngFor="let adr of adrResults.adrs">
        <code>{{adr.timestamp  | date: 'MM/dd/yyyy HH:mm:ss'}}</code><br>
        <p> {{adr.reason}} </p>
      </li>
    </ul>
  </details>
  <!-- <div class="video-box"> -->
    <div id="network-warning" [hidden]="!networkLevel || networkLevel > 2">
      <div [hidden]="!networkLevel || networkLevel > 2">
        Your network strength is too low which may cause connection issues
        with your video chat. Consider moving to a better connection or disabling your video.
      </div>
      <!-- <div [hidden]="this.sessionDurationLeft > 0">
        Session will automatically end in {{timerDisplay}}
      </div> -->
    </div>
    <!-- <div id="graceperiod-warning" >
      <fa-icon [icon]="faExclamationTriangle" size="sm"></fa-icon>

    </div> -->
    <div id="video-container" >
    <!-- <span *ngIf="this.sessionDurationLeft > 0">{{timerDisplay}}</span> -->
    <div id="remoteVideo" class="no-video" #remoteVideoContainer>
      <canvas #remoteVideo></canvas>
      <div [hidden]="!sessionStarted" id="localVideo" class="no-video" [class.leveluplocal]="!sessionStarted" #localVideoContainer>
        <video #localVideo></video>
      </div>
      </div>
    </div>

    <section class="player-info" *ngIf="!sessionStarted">
      <div class="row mr-0 mb-0">
        <div class="col">
          <div class="container text-center" *ngIf="userType === 'PARTICIPANT'">
            <p class="session-description">Your session will begin as soon as the Ambassador arrives…</p>
            <br>
          </div>
          <div class="container text-center" *ngIf="userType !== 'PARTICIPANT'">
            <p class="session-description">Your session will begin as soon as the Candidate arrives…</p>
            <button (click)="remindCandidate()" class="btn btn-text btn-remind text-purple mt-2 mb-4"
              *ngIf="userType!=='!participant'">Remind Candidate</button>
          </div>
        </div>
      </div>
    </section>
    <div class="text-danger text-center hand-device-only" *ngIf="!isScreenLockedSupported">
      Please rotate your device for better experience.
    </div>
    <section class="player-controls bg-controls" *ngIf="sessionStarted" (window:resize)="onResize($event)">
      <!-- <div class="container pr-0">
        <div class="row mr-0 mb-0">
          <a class="btn btn-icon btn-secondary ke-icon" (click)="toggleVideo()" type="button">
            <fa-icon [icon]="isVideoMuted ? faVideoSlash : faVideo"></fa-icon>
            <span class="d-none d-lg-inline icon-label">Mute Video</span>
          </a>

          <a class="btn btn-icon btn-secondary ke-icon" (click)="toggleAudio()" type="button">
            <fa-icon [icon]="isAudioMuted ? faMicrophoneSlash : faMicrophone"></fa-icon>
            <span class="d-none d-lg-inline icon-label">Mute Audio</span>
          </a>

          <div class="col d-flex align-items-center text-left">
            <img src="assets/images/icon_volume.svg">
            <div class="slidecontainer">
              <input type="range" min="1" max="100" value="50" [(ngModel)]="volume" class="slider"
                (ngModelChange)="setVolume()">
            </div>
          </div>



          <div class="col-7 text-right px-0">
            <img src="assets/images/icon_phone.svg">
            <a type="button" class="btn btn-link btn-session-end text-white" (click)="showEndModal()"><u>Leave
                Session</u></a>
          </div>
        </div>
      </div> -->

      <div class="container pr-0">
        <div class="row mr-0 ml-0 pr-3 mb-0">

          <div class="col d-flex align-items-center">
            <span class="w-100 text-center pointer-event">
              <img class="cursor-pointer" (click)="toggleAudio()" [src]="isAudioMuted ? 'assets/images/Mic-OFF.svg' : 'assets/images/Mic-ON.svg'">
            </span>
          </div>

          <div class="col d-flex align-items-center ">
            <span class="w-100 text-center">
              <img class="cursor-pointer" (click)="toggleVideo()" [src]="isVideoMuted ? 'assets/images/Video-Off.svg' : 'assets/images/Video-ON.svg'">
            </span>
          </div>

          <div class="col d-flex align-items-center">
            <span class="w-100 text-center">
              <img class="cursor-pointer" (click)="showEndModal()" src="assets/images/End-Call.svg">
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="slider text-center" [class.down]="isPCBranding">
        <p class="mt-4">Knowledge Exhange is a trademark of <br>Entrada Ventures. 2023</p>
      </div>
      <div class="pc_icon">
        <span class="w-100 text-center">
          <img class="cursor-pointer" src="assets/images/ICON-PC.svg" (click)="brandingPC()">
        </span>
      </div> -->

      <div id="confirm-popup" *ngIf="endModalVisible">
        <div class="confirm-body">
          <div>
            <div class="row">
              <div class="col text-center">
                <p class="font-color-teracotta">Are you sure you want to end your Session?</p>
              </div>
            </div>
          </div>

          <div class="confirm-tray">
            <div class="row">
              <div class="col">
                <button type="button" class="btn btn-secondary bg-light text-purple"
                  (click)="endModalVisible = false">No</button>
              </div>
              <div class="col">
                <button type="button" class="btn btn-primary bg-purple" (click)="disconnectRoom()">Yes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  <!-- </div> -->
</div>
