import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { handleError, Error } from '@utils/handleError';
import { of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root"
})
export class FileDownloaderService {
  url: string = environment.uiBase + "/assets/upload/SpeedTest_32MB.txt?t="+Math.random();

  constructor(private http: HttpClient) {}

  download() {
    const req = new HttpRequest("GET", this.url, {
      responseType: "text",
      reportProgress: true
    });
    return this.http.request(req);
  }

}
