<section id="confirmIsi">
  <div id="terms">
      <div class="font-color-teracotta text-center">
        <h1 class="mt-3 bold">Please confirm you have read the Terms of Use before joining your session</h1>
      </div>
      <div>
        <h1 class="mt-3 font-color-teracotta  mb-4">voce&trade; - Terms of Use</h1>

        <p>
          There is no cost to register for this support program. The sessions for this program are private and confidential. We do insist that registered users abide by the rules and policies detailed below.
        </p>

        <p>
          By registering, you agree not to provide any content which is defamatory, abusive, hateful, threatening, spam or spam-like, likely to offend, contains adult or objectionable content, contains personal information of others, risks copyright infringement, encourages unlawful activity, or otherwise violates any laws.
        </p>

        <p>
          We reserve the right to remove noncompliant members for any reason. Users deemed to be abusing the program may be banned with or without warning.
        </p>
        <p>
          These terms may be changed at any time without notice.
        </p>

        <p>
          If you do not agree with these terms, please do not register, or use this Service.
        </p>

        <br>
        <p><strong>Terms of Use Disclaimer</strong></p>

        <p>
          Ambassadors provide general information about their personal experience and are being compensated for their services. A considerable effort has been made in good faith to ensure that material accessible from this site is accurate. Despite this effort, it is clear that errors are inevitable. Consequently, no guarantees are expressed or implied as to the accuracy, timeliness, currency, or completeness of any information authored by persons at or agents of voce&trade; or its affiliates, or accessible using links from this site. Nor is any warranty made that the information obtained from this site or that of an affiliate is valuable or useful for any purpose. A user assumes full responsibility for any actions taken based on information obtained from this website. In particular, we emphasize that the information available through this site should not be interpreted as medical or professional advice. All medical information, from this or any other source, needs to be carefully reviewed with your personal health care provider before being acted upon in any way. Sessions may be monitored and recorded for record-keeping, training, and quality-assurance purposes.
        </p>

        <br>
        <p><strong>Acceptable Use</strong></p>

        <p>
          Use of information from this program for commercial purposes, other than those approved by voce&trade;, is not permitted. Use of voce&trade; icons and logos is restricted: these icons and logos may not be used without the permission of voce&trade;. Use of US mail addresses, email addresses, or phone numbers for the purpose of contacting individuals at voce&trade; for any purpose other than the conduct of the business of the organization is not permitted.
        </p>

        <br>
        <p><strong>Copyright</strong></p>

        <p>
          Unless otherwise noted, all documents and conversations accessed from this program are viewed as property of voce&trade;, with all rights reserved. Use of any data or other materials accessed from this site without the permission of the authors may result in action being taken against offenders.
        </p>
      </div>
  </div>
  <div class="text-center">
    <div class="custom-control custom-checkbox checkbox-isi" [class.active]="isTermsChecked" (click)="confirmIsiRead()">
      <input type="checkbox" class="custom-control-input" id="checkedTerms" [checked]="isTermsChecked">
      <label class="custom-control-label custom-control-label-isi" for="checkedTerms">
        <span class="ml-2">I accept</span>
      </label>
    </div>
  </div>
</section>
