import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { StateService } from '@api/state.service';
import { AuthService } from '@api/auth.service';

import { CaptainsService } from '@api/captains.service';
import { Captain } from '@models/captain';


@Component({
  selector: 'oamw-select-faculty',
  templateUrl: './select-faculty.component.html',
  styleUrls: ['./select-faculty.component.scss']
})
export class SelectFacultyPage {

  selectedTopic: string;
  captainList: Captain[] = [];

  selectedFaculty: number;
  selectedCaptain: string;
  selectedIdx: number;
  isLoggedIn = true;


  constructor(private router: Router,
              private state: StateService,
              private captainsService: CaptainsService,
              private location: Location,
              private auth: AuthService) { }

  ngOnInit(): void {
    !this.state.hasToken() && this.router.navigate(['/']);
    this.auth.isLoggedIn.subscribe( status => this.isLoggedIn = status );
    this.getCaptains();
  }

  gotoSession() {
    this.state.selectedCaptainByParticipant = this.selectedCaptain;
    this.router.navigate(['select-session']);
  }

  getCaptains() {
    this.captainsService
      .getCaptainsByTopic(this.state.selectedTopicByParticipant)
      .subscribe(captains => {
        this.captainList = captains;
      });
  }

  goBack() {
    this.location.back();
  }

  scrollToTop() {
    if (window.innerWidth > window.innerHeight) {
      window.scrollTo ({
        top: 100,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo ({
        top: 250,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

    protected readonly document = document;
  protected readonly window = window;
}
