<div class="grad1">
    <section id="landing" class="mt-auto">
        <div class="container">
            <div class="row m-align-center">
                <div class="col-sm  ol">
                    <div class="row m-hide">
                        <div class="col pb-5">
                          <img src="assets/images/voce/VoceLogo-White.png" class="landing-logo">
                          <p class="tagline">The Power of Connection</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm pb-4">
                            <h2 class="landing-page-title">Welcome to <b>voce&trade;</b>,<br>
                                an easy way for you to<br>
                                connect with others</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm login-button">
                            <button type="text" [routerLink]="['/signup']" class="btn btn-primary btn-desktop bg-purple mt-3 mr-3">Sign Up</button>
                            <button type="text" [routerLink]="['/login']" class="btn btn-primary btn-desktop bg-purple mt-3">Login</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm of d-hide">
                  <img src="assets/images/voce/VoceLogo-White.png" class="landing-logo pt-3 pb-3">
                  <p class="tagline">The Power of Connection</p>
                </div>
                <div class="col-sm landing-hero-align">
                    <img src="assets/images/voce/LandingPage_IMG.png" class="landing-hero-img">
                </div>
            </div>
            <div class="row">
                <div class="landing-head">
                    <h2 class="landing-page-title font-color-teracotta"><b>What is voce&trade;?</b></h2>
                </div>
            </div>
            <div class="landing-details">
              <p>
                In Italian, “voce” means voice. Hearing stories from patients like yourself, can help empower you to actively participate in your own healthcare decisions because you will feel more informed to have better conversations with your care team.
              </p>
              <p>
                This first-in-class, 1:1 video chat platform directly connects patients who are new to treatment with an experienced Ambassador of your choice, to help you and your doctor make more informed health decisions.
              </p>
              <p>
                Through this platform, you will have access to resource materials to further support and educate you along your health journey. Additionally, you will be able to select from a series of discussion topics that are designed to help move you forward in your journey.
              </p>
            </div>
            <div class="row">
              <div class="landing-head">
                <h2 class="landing-page-title font-color-teracotta"><b>voce&trade; provides</b></h2>
              </div>
            </div>
            <div class="row landing-patient-container">
                <div class="col-sm landing-patient-align">
                  <div class="provides-img">
                    <img src="assets/images/selection.svg" class="landing-patient-img">
                  </div>
                  <h2 class="provides-head">Selection</h2>
                  <h1 class="font-color-teracotta">
                    voce&trade; allows them to select an ambassador based on their profile
                  </h1>
                </div>
              <div class="col-sm landing-patient-align">
                <div class="provides-img">
                  <img src="assets/images/connection.svg" class="landing-patient-img">
                </div>
                <h2 class="provides-head">Connection</h2>
                <h1 class="font-color-teracotta">
                  voce&trade; allows them to connect in a 1:1 live forum.
                </h1>
              </div>
              <div class="col-sm landing-patient-align">
                <div class="provides-img">
                  <img src="assets/images/assurance.svg" class="landing-patient-img">
                </div>
                <h2 class="provides-head">Assurance</h2>
                <h1 class="font-color-teracotta">
                  voce&trade; provides an easy-to-share resource library.
                </h1>
              </div>
            </div>
        </div>
    </section>
  </div>
